<template>
	<div>
	<div id="account-actions" class="-logged">
        <div class="x-button-actions" id="account-actions-mobile">
          <div class="-outer-wrapper">
            <div class="-left-wrapper">
              <router-link to="/" class="-item-wrapper -promotion">
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/home.png"
                  />
                  <img
                    src="../assets/images/home.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">หน้าแรก</span>
              </router-link>
              <a
                href="javascript:void(0);"
                class="-item-wrapper -deposit js-account-approve-aware"
                data-toggle="modal"
                data-target="#depositModal"
                @click="checkPromotions()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icdeposit.png"
                  />
                  <img
                    src="../assets/images/icdeposit.png"
                    class="-ic-img"
                    alt="Data-28 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ฝากเงิน</span>
              </a>

              
            </div>

            <router-link to="/play" class="-center-wrapper">
              <div
                class="-selected"
                style="background-image: url('https://firebasestorage.googleapis.com/v0/b/data-28-1dbe0.appspot.com/o/images%2Fezgif-2-3eb68958c0%20(1).png?alt=media&token=d808edb7-9ecf-4133-a56e-7b72190c4bcd')"
              >
                <!-- <span class="-text">เข้าเล่น</span> -->
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-top.png"
                  class="-top-curve lazyload"
                  alt="Data-28 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-bottom.png"
                  class="-bottom-curve lazyload"
                  alt="Data-28 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
              </div>
            </router-link>
            <div
              class="-fake-center-bg-wrapper"
              style="position: absolute;z-index: 1;width: 100%;height: 50px;overflow: hidden;left: 0;bottom: 0;"
            >
              <svg viewbox="-10 -1 30 12">
                <defs>
                  <linearGradient
                    id="rectangleGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="20%" stop-color="#393854" />
                    <stop offset="100%" stop-color="#100e1e" />
                  </linearGradient>
                </defs>
                <path
                  d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"
                />
              </svg>
            </div>

            <div class="-right-wrapper">
				      <a
                href="javascript:void(0);"
                class="-item-wrapper -withdraw js-account-approve-aware"
                data-toggle="modal"
                data-target="#withdrawModal"
                @click="moveCreditToWallet()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icwithdraw.png"
                  />
                  <img
                    src="../assets/images/icwithdraw.png"
                    class="-ic-img"
                    alt="Data-28 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ถอนเงิน</span>
              </a>
              <a
                href="#"
                class="-item-wrapper -line"
                rel="noopener noreferrer"
                @click="toggleDropdown"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/shiba888-ic-mobile-left-1.png"
                  />
                  <img
                    src="../assets/images/shiba888-ic-mobile-left-1.png"
                    class="-ic-img"
                    alt="Data-28 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ติดต่อเรา</span>
              </a>
              <div v-if="isDropdownVisible" style="display:flex; position: absolute; bottom: 100%; right: 10px; flex-direction: column; gap: 10px; z-index: 1;">
                <div class="sub-button" style="width: 50px; height: 50px; background-color: #4ba2ff; border-radius: 50%; display: flex; align-items: center; justify-content: center; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); cursor: pointer;">
                  <a href="https://www.facebook.com/profile.php?id=61550018581644&locale=th_TH" target="_blank"><img src="../assets/images/icons/facebook.png" width="50"></a>
                </div>
                <div class="sub-button" style="width: 50px; height: 50px; background-color: #4ba2ff; border-radius: 50%; display: flex; align-items: center; justify-content: center; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); cursor: pointer;">
                  <a href="https://t.me/adalldata28" target="_blank"><img src="../assets/images/icons/telegram.png" width="50"></a>
                </div>
                <div class="sub-button" style="width: 50px; height: 50px; background-color: #4ba2ff; border-radius: 50%; display: flex; align-items: center; justify-content: center; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); cursor: pointer;">
                  <a href="https://lin.ee/kFUNPGv" target="_blank"><img src="../assets/images/icons/line.png" width="50"></a>
                </div>
            </div>
            </div>
            <div class="-fully-overlay js-footer-lobby-overlay"></div>
          </div>
        </div>
      </div>
    <div class="-main-footer-container js-replace-main-footer-container">
	<footer class="x-footer ">
		<div class="container-fluid -footer-wrapper">
		<div class="-footer-bottom-wrapper">
			<div>
			<p class="mb-0" style="padding-top:20px;">
				Copyright © 2022 Data-28 All Rights Reserved.
			</p>
			</div>
		</div>
		</div>
	</footer>
	</div>
	</div>
</template>
<script>
import firebase from 'firebase/app'
import router from './../router'
export default {
  name: 'Footer',
  data() {
    return {
      isDropdownVisible: false,
      disabled: false
    };
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
      }
    },
    async moveCreditToWallet(){
      if(!this.disabled){
        this.disabled = true
        this.$store.commit('user/SET_LOADING', true)
        const user = this.getUserId();
        this.$store.dispatch('user/moveCreditToWallet').finally(res => {
          this.$store.dispatch('user/getLastBonus', user).finally(res => {
            this.$store.commit('user/SET_LOADING', false)
            if(this.lastbonus.turncredit){
              this.$store.commit("user/SET_ALERT_WITHDRAWALL", true);
            }
          }).catch(err => { console.error(err) })
        }).catch(err => { console.error(err) })
        setTimeout(() => {
          this.disabled = false
        }, 2000)
      }
    },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    }
  }
}
</script>
